<template>
  <v-container>
    <v-row
    ><v-col cols="auto"> <h1>Laporan</h1> </v-col></v-row
    >
    <v-row class="d-flex align-center mb-4 pt-4" no-gutters>
      <!-- Select Report Type with fixed width -->
      <v-col class="pb-2 pr-2" cols="auto" sm="6" md="4" lg="2" xl="1" xxl="1">
        <v-select
          v-model="selectedReportType"
          :items="reportTypes"
          label="Pilih Jenis Laporan"
          variant="outlined"
          density="compact"
          dense
          hide-details
        ></v-select>
      </v-col>

      <!-- Start Date -->
      <v-col cols="auto" class="pb-2 pr-2" sm="12" md="4" lg="2" xl="1" xxl="1">
        <v-text-field
          v-model="startDate"
          label="Start Date"
          type="date"
          variant="outlined"
          density="compact"
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <!-- End Date -->
      <v-col cols="auto" class="pb-2" sm="12" md="4" lg="2" xl="1" xxl="1">
        <v-text-field
          v-model="endDate"
          label="End Date"
          type="date"
          variant="outlined"
          density="compact"
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <!-- Fetch Report Button -->
      <v-col cols="auto" class="ml-4" xs="12" sm="6" md="4" lg="2" xl="1" xxl="1">
        <v-btn color="primary" @click="fetchReportData">
          Filter Laporan
        </v-btn>
      </v-col>
    </v-row>

    <!-- Data Table -->
    <v-data-table
      :headers="tableHeaders"
      :items="reportData"
      :items-per-page="itemsPerPage"
      v-model:page="currentPage"
      class="elevation-1"
    >
      <template v-slot:[`item.index`]="{ index }">
        {{ (currentPage - 1) * itemsPerPage + index + 1 }}
      </template>
      <template v-slot:[`footer.prepend`]>
        <div class="font-weight-bold text-h6" style="text-align: left; padding: 16px;">
          Total: {{ formatCurrency(totalAmount) }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="currentReportType === 'Income'">
            <v-btn
              append-icon="mdi-delete"
              @click="deleteItem(item)"
              color="red"
              >Hapus</v-btn
            >
            <v-btn
              class="ml-2"
              append-icon="mdi-pencil"
              @click="openEditDialog(item)"
              color="green"
              >Ubah</v-btn
            >
          </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <v-row>
            <div class="pa-4">
              <span v-if="isEditMode">Edit Income</span>
              <span v-else>Tambah Income</span>
            </div>
            <v-spacer />
            <v-btn
              class="pa-4"
              icon
              color="primary"
              @click="dialog = !dialog"
              aria-label="Close"
              variant="plain"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation v-model="validUserForm">
          <!-- Form Fields for Add/Edit -->
          <p><strong>Tanggal Pembayaran </strong></p>
            <v-text-field
              v-model="editedItem.paymentDate"
              variant="outlined"
              type="date"
              :rules="[requiredRule]"
            ></v-text-field>
          <v-btn
            color="primary"
            @click="saveItem"
            block
            variant="outlined"
            size="large"
            :disabled="!validUserForm"
          >Simpan</v-btn
          >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import apiService from "@/services/api";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useStore } from "vuex";

export default {
  name: "IncomeExpensesPage",
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      selectedReportType: "Income",
      currentReportType: "",
      validUserForm: false,
      reportTypes: ["Income", "Expenses"],
      startDate: dayjs().subtract(1, 'months').format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      reportData: [],
      tableHeaders: [
        { title: '#', value: 'index', sortable: false },  // Index column
        { title: "Tanggal", value: "date" },
        { title: "Tipe / Term", value: "type" },
        { title: "Deskripsi", value: "description" },
        { title: "Jumlah", value: "amount" },
        { title: "Actions", value: "actions", sortable: false }, // Column for actions
      ],
      totalAmount: null,
      reportStartDate: null,
      reportEndDate: null,
      dialog: false,
      isEditMode: false,
      editedItem: {
        id: null,
        paymentDate: "",
      },
    };
  },
  computed: {
    totalAmountFormatted() {
      return this.formatCurrency(this.totalAmount);
    },
  },
  created() {
    this.fetchReportData();
  },
  setup() {
    const store = useStore(); // Use store inside setup
    return { store };
  },
  methods: {
    openEditDialog(item) {
      this.editedItem = {
        id: item.id,
        paymentDate: item.rawDate,
      };
      this.isEditMode = true;
      this.dialog = true;
    },
    async saveItem() {
      const requestBody = {
        paymentDate: this.editedItem.paymentDate,
      };

      try {
        let response;
        if (this.isEditMode) {
          response = await apiService.put(
            `/payments/${this.editedItem.id}`,
            requestBody
          );
        }

        console.log(response.data.responseCode, response.data.responseMessage);

        if (response.data.responseCode === "00") {
          this.store.dispatch("notification/showNotification", {
            message: "Data berhasil disimpan!",
            type: "success",
          });
          this.fetchReportData(); // Refresh the resident list
          this.dialog = false;
        } else {
          this.store.dispatch("notification/showNotification", {
            message: `Data gagal disimpan ${response.data.responseMessage}`,
            type: "error",
          });
          this.dialog = false;
        }
      } catch (error) {
        console.error("Error saving resident:", error);
        this.store.dispatch("notification/showNotification", {
          message: `Data gagal disimpan ${error}`,
          type: "error",
        });
      }
    },
    async fetchReportData() {
      if (!this.selectedReportType || !this.startDate || !this.endDate) {
        this.$alert("Please select report type and date range.");
        return;
      }

      const startDateObj = dayjs(this.startDate);
      const endDateObj = dayjs(this.endDate);

      if (endDateObj.isBefore(startDateObj)) {
        Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Tanggal tidak sesuai!',
            });
        return;
      }

      const startDateFormatted = startDateObj.format("YYYY-MM-DD");
      const endDateFormatted = endDateObj.format("YYYY-MM-DD");


      const endpoint =
        this.selectedReportType === "Income"
          ? "/reports/incomes"
          : "/reports/expenses";
      const params = {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };

      try {
        const response = await apiService.get(endpoint, { params });
        if (response.data.responseCode === "00" && response.data.responseData) {
          this.currentReportType = this.selectedReportType;
          const responseData = response.data.responseData;
          const itemsArray = responseData.items;

          if (Array.isArray(itemsArray)) {
            this.reportData = itemsArray.map((item) => ({
              date: this.formatDate(item.date),
              id: item.id,
              rawDate: item.date,
              description: item.description,
              amount: this.formatCurrency(parseFloat(item.amount)),
              type: item.type || item.term + ' bulan'
            }));
            console.log("report data : ", this.reportData);

            // Store totalAmount, reportStartDate, reportEndDate
            this.totalAmount = parseFloat(responseData.totalAmount);
            this.reportStartDate = responseData.startDate;
            this.reportEndDate = responseData.endDate;
          } else {
            this.reportData = [];
            console.error("Expected items to be an array");
            this.$alert("No data found.");
          }
        } else {
          this.reportData = [];
          this.$alert(response.data.responseMessage || "No data found.");
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
        this.$alert("An error occurred while fetching the report data.");
      }
    },
    updateStartDate(val) {
      this.startDateFormatted = this.formatDate(val);
    },
    updateEndDate(val) {
      this.endDateFormatted = this.formatDate(val);
    },
    formatDate(date) {
      return dayjs(date).format("DD MMMM YYYY");
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(amount);
    },
    $alert(message) {
      // Simple alert method; you can replace this with your preferred notification system
      alert(message);
    },
    async deleteItem(item) {
      Swal.fire({
        title: "Hapus Data Report",
        text: "Apakah Anda yakin mau menghapus data report ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Perform the delete action or API call here

          //        this.items = this.items.filter(i => i.id !== item.id);
          const response = await apiService.delete(`/payments/${item.id}`);

          try {
            if (response.data.responseCode === "00") {
              this.store.dispatch("notification/showNotification", {
                message: `Data berhasil dihapus!`,
                type: "success",
              });
              this.fetchReportData();
            } else {
              this.store.dispatch("notification/showNotification", {
                message: `Data gagal dihapus! ${response.data.responseMessage}`,
                type: "error",
              });
              Swal.fire("Terdapat error sistem", "-", "error");
            }
          } catch (error) {
            this.store.dispatch("notification/showNotification", {
              message: `Data gagal dihapus`,
              error,
              type: "error",
            });
            Swal.fire("Terdapat error sistem!", error, "error");
          }
        }
      });
    },
  },
};
</script>
  
<style scoped>
</style>
